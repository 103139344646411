import { render } from 'react-dom';

import './style.scss';
import Style from './Style';

// Icon List block
document.addEventListener('DOMContentLoaded', () => {
	const iconListEls = document.querySelectorAll('.wp-block-ilb-icon-list');
	iconListEls.forEach(iconListEl => {
		const attributes = JSON.parse(iconListEl.dataset.attributes);

		render(<>
			<Style attributes={attributes} clientId={attributes.cId} />

			<IconList attributes={attributes} />
		</>, iconListEl);

		iconListEl?.removeAttribute('data-attributes');
	});
});

const IconList = ({ attributes }) => {
	const { isTitle, title, isDesc, desc, lists, isListLinkInNewTab, position, isHeaderSep } = attributes;

	return <div className={`ilbIconList ${position}`}>
		{isTitle || isDesc ? <div className='header'>
			{isTitle && title && <h3 className='title' dangerouslySetInnerHTML={{ __html: title }} />}

			{isDesc && desc && <p className='description' dangerouslySetInnerHTML={{ __html: desc }} />}

			{isHeaderSep && <span className='separator' />}
		</div> : null}

		<ul className='lists'>
			{lists?.map((list, index) => {
				const { icon, text, link } = list;

				return <li key={index} className='list'>
					{icon?.class && <i className={`icon ${icon.class}`}></i>}

					{link ?
						<a className='link text' href={link} target={isListLinkInNewTab ? '_blank' : '_self'} rel='noreferrer' dangerouslySetInnerHTML={{ __html: text }} /> :
						<p className='text' dangerouslySetInnerHTML={{ __html: text }} />}
				</li>
			})}
		</ul>
	</div>
}